import React, { Component } from 'react';
import { MAP_NODES } from '../utility/constants';
import { Row, Col, Button } from 'react-bootstrap';
import {downloadUrilFile} from '../utility/parserHTMLencoding';
import print from 'print-js';
import Papa from 'papaparse';

class NodeTable extends Component {
    constructor(props) {
        super(props);
		
        this.state = {
            nodes: []
        };
		
		// Function Bindings
        this.getStandards = this.getStandards.bind(this);
        this.printNodesTable = this.printNodesTable.bind(this);
        this.exportNodesTableCSV = this.exportNodesTableCSV.bind(this);
    }

    getStandards() {
        fetch( MAP_NODES + "getNodeTable?mapId=" + this.props.mapId)
            .then(res => res.json())
            .then((result) => {
                if (result) {
                    const nodesData = result.map(nodeEntry => {
                        return {
                            nodeId:nodeEntry.nodeId,
                            textId:nodeEntry.textId,
                            title:nodeEntry.title,
                            description:nodeEntry.shortTitle,
                            nodeStandardCSV:nodeEntry.nodeStandardCSV
                        };
                    });
					
                    this.setState({
                        nodes: nodesData
                    });
                }
            });
    }

    printNodesTable(){
        print({printable: "nodeTable", type: 'html'});
    }

    exportNodesTableCSV(){
        var parsedCsv = Papa.unparse(this.state.nodes);
		
        // Determine which approach to take for the download
        var blob = new Blob([parsedCsv], {
            type: "text/csv"
        }); 
		
        var url = window.URL.createObjectURL(blob);
		
        downloadUrilFile(url,'testFile.csv');
    }
	
	componentDidMount() {
		if (this.props.mapId) {
			this.getStandards();
		}
	}

    render() {
        let nodeList = (this.state.nodes.length) ? this.state.nodes.map(item =>
            <tr
				key={item.nodeId}
				onMouseEnter={() => this.props.highlightNode([item.nodeId])}
				onMouseLeave={this.props.unHighlightNodes}
			>
                <td dangerouslySetInnerHTML={{'__html':item.textId}}></td>
                <td dangerouslySetInnerHTML={{'__html': item.title}}></td>
                <td dangerouslySetInnerHTML={{'__html': item.description}}></td>
                <td> {item.nodeStandardCSV}</td>
            </tr>) : <tr><td>No Nodes to Display</td></tr>;
        
		return (
            <div>
                <Row id="nodeTableBtns">
                    <Col> </Col>
                    <Col>
                        <Button variant="primary" onClick={this.printNodesTable}>Print table</Button>
                    </Col>
                    <Col>
                        <Button variant="primary" onClick={this.exportNodesTableCSV}>Export to CSV</Button>
                    </Col>
                </Row>
                <table className="table" id="nodeTable">
					<thead>
						<tr>
							<th>Node Id</th>
							<th>Title</th>
							<th>Description</th>
							<th>Standards</th>
						</tr>
					</thead>
					<tbody>
                        {nodeList}
                    </tbody>
                </table>
            </div>
        );
    }
}
export default NodeTable;
